:root {
  --white: #ffffff;
  --primary-color: #27783F;
  --secondary-color: #3f51b5;
  --danger-color: #f50057;
  --main-bg-color: #C0C64B;
  --main-text-color: rgba(0, 0, 0, 0.80);
  --main-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Avenir Book", Helvetica, Arial, sans-serif;
  color: var(--main-text-color);
  background-color: var(--main-bg-color);
  height: 100vh;
  overflow: auto;
}

h1, h2, h3, h4, h5, h6, p, small {
  margin: 0 0 1rem;
}

.auth-row {
  max-width: 650px;
  margin: 0 auto 40px;
  display: flex;
  align-items: center;
}

.auth-row > div:first-child,
.auth-row > div:last-child {
  flex: 1;
}

.auth-row > div:nth-child(2) {
  padding: 0 2.5rem;
  text-transform: uppercase;
  text-align: center;
}

@media (max-width: 767px) {
  .auth-row {
    flex-direction: column;
  }

  .auth-row > div {
    margin-bottom: 1rem;
    flex: auto;
    width: 100%;
    max-width: 400px;
  }
}

.btn {
  background-color: var(--secondary-color);
  border: 2px solid var(--secondary-color);
  color: var(--white);
  text-transform: uppercase;
  border-radius: 4px;
  font-size: 14px;
  padding: 0.563em 1.25em;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
  cursor: pointer;
  outline: none;
  text-decoration: none;
  display: inline-block;
}

.link {
  border: none;
  outline: none;
  text-decoration: none;
  color: var(--danger-color);
  text-transform: uppercase;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.link:hover,
.link:focus {
  text-decoration: none;
}

.error-msg {
  color: var(--danger-color);
  font-size: 14px;
  max-width: 400px;
  margin: 0 auto 2rem;
  text-align: center;
}
