.wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 2rem;
}

.logo {
  max-width: 90px;
}

.name {
  color: var(--primary-color);
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 2.2rem;
}

@media (max-width: 480px) {
  .logo {
    max-width: 60px;
  }

  .name {
    font-size: 1.5rem;
  }
}