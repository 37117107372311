.action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.label {
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.forgotPassword {
  text-align: center;
}