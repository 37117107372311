.container {
  height: 100vh;
  padding: 1.5rem;
}

.panel {
  margin: 3rem auto;
  padding: 4rem 2rem;
  max-width: 750px;
  width: calc(100% - 4rem);
  border-radius: 3px;
  background-color: var(--white);
  box-shadow: var(--main-shadow);
}

@media (max-width: 767px) {
  .panel {
    margin: 0 auto;
  }
}
