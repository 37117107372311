.form {
  max-width: 250px;
  margin: 0 auto 30px;
}

.label {
  text-align: center;
  max-width: 242px;
}

.backToLogin {
  text-align: center;
}
